import { Injectable } from '@angular/core';
import { from, of, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from './services/common/errorService';

@Injectable()
export class CanDeactivateGuard {
    constructor(private errorService: ErrorService) {}

    canDeactivate(component, currentRoute, currentState, nextState) {
        if (nextState.url.startsWith('/init')) {
            return true;
        }

        if (component?.canDeactivate) {
            const routeToActivate = nextState && nextState.url;

            if (routeToActivate === '/reload?skipCanDeactivateCheck=true') {
                return true;
            } else {
                const canDeactivate = component.canDeactivate(routeToActivate);
                if (canDeactivate instanceof Promise || canDeactivate instanceof Observable) {
                    return from(canDeactivate).pipe(
                        catchError((err) => {
                            this.errorService.handle(err);
                            return of(false);
                        }),
                    );
                } else {
                    return canDeactivate;
                }
            }
        }

        return true;
    }
}
